.console {
  font-family: Calibri, Arial, Helvetica, sans-serif;
}

.console.container {
  height: 100vh;
  width: 100vw;
}

.console.container nav {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #b4b4b4;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 7px #969696;
}

.console.logo-wrapper {
  display: flex;
  height: 50px;
  margin-left: 30px;
}

.console.logo-wrapper:focus {
  outline: none;
}

.console.main {
  display: flex;
  width: 100%;
  height: calc(100% - 80px);
  background-color: #f5f5f5;
}

.console.sidebar {
  flex: 0 0 280px;
  height: 100%;
  border-right: 1px solid #b4b4b4;
  background-color: #e6e6e6;
}

.console.content {
  flex: 1 0;
}

.console.sidetab.title {
  display: flex;
  align-items: center;
  padding: 7px 5px;
  font-size: 1.5em;
  font-weight: 500;
  color: #505050;
  cursor: pointer;
  text-decoration: none;
}

.sidebar > .console.sidetab:first-child {
  margin-top: 10px;
}

.console.sidetab.active,
.console.sidetab:hover {
  color: #000;
  background-color: #d2d2d2;
}

.console.sidetab:focus {
  outline: none;
}

.console.large-icon {
  width: 35px;
  height: 35px;
  background-color: #505050;
  margin: 10px 15px 10px 10px;
}

.console.small-icon {
  width: 20px;
  height: 20px;
  border: 3px solid #505050;
  box-sizing: border-box;
  margin: 0 12px 0 22px;
}

.console.subtab {
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px 10px 10px 0;
  font-size: 1.1em;
  font-weight: 500;
  color: #505050;
  cursor: pointer;
  text-decoration: none;
}

.console.subtab.active,
.console.subtab:hover {
  color: #000;
  background-color: #bebebe;
}

.console.selected-bar {
  position: fixed;
  width: 5px;
  height: 42px;
  background-color: #ffa801;
}
