.login.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login.form {
  display: flex;
  flex-direction: column;
  width: 400px;
  font-family: Calibri, Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1.5em;
}

.login.logo-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
}

.login.logo-wrapper:focus {
  outline: none;
}

.login.form img {
  width: 300px;
}

.login.form label {
  color: #787878;
  margin-bottom: 3px;
}

.login.form input {
  border-radius: 5px;
  padding: 15px 15px;
}

.login.form input::placeholder {
  color: #d2d2d2;
  font-family: Calibri, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.1em;
}

.login.form input:focus {
  outline-color: #ffa801;
}

.login.field {
  font-size: 0.75em;
  border: 1px solid #e6e6e6;
  margin-bottom: 10px;
}

.login.submit {
  cursor: pointer;
  margin-top: 20px;
  font-family: Calibri, Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
}

.login.submit:hover {
  outline: 2px solid #ffa801;
}

.login.submit:active {
  border-color: #ffa801;
  background-color: #ffa801;
}
